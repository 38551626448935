import React from 'react'
import PropTypes from 'prop-types'
import { AreasSection, Header, Footer } from '../components'
import { Helmet } from 'react-helmet'

import '@fortawesome/fontawesome-svg-core/styles.css'
import { config } from '@fortawesome/fontawesome-svg-core'
config.autoAddCss = false

import 'bootstrap/dist/css/bootstrap.min.css'
import '../css/theme.scss'
import ThemeProvider from '../context/themeContext'

import useScripts, { scriptSpecialCases } from '../hooks/useScripts'


const Layout = ({ children }) => {

  const scripts = useScripts()

  return (
    <ThemeProvider>
      <Header />
      
      {/* Helmet para scripts en todas las paginas */}
      {/* Hay dos mapeos porque no se puede usar Fragments dentro de Helmet */}
      {scripts.map((script) =>
        script.enable === true ? (
          script.name.toLowerCase() === 'google-analytics' ||
          script.name.toLowerCase() === 'pixel-fjs' ||
          script.name.toLowerCase() === 'facebook-pixel' ||
          script.name.toLowerCase() === 'matomo-tag-manager' ||
          script.name.toLowerCase() === 'google-tag-manager' ? (
            scriptSpecialCases(script)
          ) : (
            <Helmet>
              <script
                src={script.source}
                type="text/javascript"
                name={script.name}
                siteId={script.siteId}
                key={script.id}
                async
              ></script>
            </Helmet>
          )
        ) : (
          <></>
        )
      )}

      <main className="flex-grow-1 primary-background">{children}</main>
      <Footer />
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
