import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import { Helmet } from 'react-helmet'

const globalConfig = graphql`
  query GlobalScripts {
    allStrapiHomeFjs {
      nodes {
        scripts {
          enable
          id
          name
          source
          siteId
        }
      }
    }
  }
`
const GlobalScripts = () => useStaticQuery(globalConfig)

export const scriptSpecialCases = (script = {}) => {
  const { name, source, id, siteId } = script

  //para los casos especiales
  const cases = {
    'google-analytics': (
      <Helmet key={id}>
        <script key={id}>
          {`window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${source.match(/G-([\w\d]+)/g)}');`}
        </script>
      </Helmet>
    ),
    'facebook-pixel': (
      <Helmet key={id}>
        <script key={id}>
          {`!function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window,document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '${source}');
            fbq('track', 'PageView');`}
        </script>
        <noscript>
          {`<img
            height="1"
            width="1"
            src={'https://www.facebook.com/tr?id=${source}&ev=PageView&noscript=1}'/>`}
        </noscript>
      </Helmet>
    ),
    'pixel-fjs': (
      <Helmet key={id}>
        <script key={id}>
          {`(function(d,t,u,s,c,f){f=function(m){m=new Date();return m.getFullYear()+''+(m.getMonth()+1)+''+m.getDate()+'T'+m.getHours()+''+m.getMinutes()+''+m.getSeconds()};
          u='https://widgets-static.embluemail.com/accounts/${source}/scripts/sw_12085.js?ts='+f();s=d.createElement(t);
          s.async=1;s.src=u;c=d.getElementsByTagName(t)[0];c.parentNode.insertBefore(s,c);})(document,'script');`}
        </script>
      </Helmet>
    ),
    'matomo-tag-manager': (
      <Helmet key={id}>
        <script key={id}>
          {`
            var _paq = window._paq = window._paq || [];
            /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
            _paq.push(['trackPageView']);
            _paq.push(['enableLinkTracking']);

            (function() {
              var u="${source}"; // Replace with your Matomo URL ("//matomo.ucc.edu.ar/")
              _paq.push(['setTrackerUrl', u+'matomo.php']);
              _paq.push(['setSiteId', '${siteId}']); // Replace with your Matomo site ID
              var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
              g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
            })();
          `}
        </script>
      </Helmet>
    ),
    'google-tag-manager': (
      <Helmet key={id}>
        <script key={id}>
          {`
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${source}');
          `}
        </script>
        <noscript>
          {`<iframe src='https://www.googletagmanager.com/ns.html?id=${source}'
              height="0" width="0" style={{ display: 'none', visibility: 'hidden' }}>
            </iframe>`}
        </noscript>
      </Helmet>
    ),
  }

  return cases[name] || null
}

const useScripts = () => {
  const { allStrapiHomeFjs } = GlobalScripts()
  const { nodes } = allStrapiHomeFjs || {}

  const scripts = nodes[0].scripts

  return scripts
}

export default useScripts
